import { graphql } from "gatsby"
import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { useIntl } from "gatsby-plugin-react-intl"
import { setSeoData } from "../services/seo.service"
import { getCarouselImageOG } from "../components/common/functions"

export default function Gallery({
  data: { nodeGallery: gallery },
  pageContext,
}) {
  const isSSR = typeof window === "undefined"
  const BlockGallery = React.lazy(() =>
    import("../components/common/blockGallery")
  )
  const HeroBanner = React.lazy(() =>
    import("../components/common/heroBanner/heroBanner")
  )

  const {
    title,
    field_seo,
    relationships: { node__gallery_item: galleryItems, field_hero: heroItems },
  } = gallery

  const intl = useIntl()

  const carouselFiltered = heroItems.filter(
    hero => hero.relationships.media !== null
  )

  const seoData = setSeoData({
    lang: intl.locale,
    title: title,
    field_seo: field_seo,
    image: process.env.GATSBY_URL + getCarouselImageOG(carouselFiltered),
  })

  return (
    <Layout pageContext={pageContext}>
      <Seo data={seoData} />
      {!isSSR && (
        <React.Suspense fallback="loading.... ">
          {carouselFiltered && (
            <HeroBanner hero={carouselFiltered} isHeightSmall={1} />
          )}
          {galleryItems && <BlockGallery items={galleryItems} />}
        </React.Suspense>
      )}
    </Layout>
  )
}

export const query = graphql`
  query ($id: String!) {
    nodeGallery(id: { eq: $id }) {
      ...galleryFields
    }
  }
`
